











































import {
  computed,
  defineComponent,
  nextTick,
  onBeforeMount,
  onMounted,
  onUnmounted,
  ref,
  useContext,
  useFetch,
  useMeta,
  useRoute
} from '@nuxtjs/composition-api';
import { SfLoader } from '@storefront-ui/vue';
import { CacheTagPrefix, useCache } from '@vue-storefront/cache';
import { onSSR } from '@vue-storefront/core';
import merge from 'lodash.merge';

import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';
import { useConfig, useUiState } from '~/composables';
import VaimoSubscriptionModal from '~/diptyqueTheme/components/organisms/VaimoSubscriptionModal.vue';
import VaimoTransparencyModal from '~/diptyqueTheme/components/organisms/VaimoTransparencyModal.vue';
import {
  useCanonical,
  useContentfulPage,
  useGoogleTagManager,
  useNosto,
  useRequestPath,
  useStructuredData
} from '~/diptyqueTheme/composable';
import { useContentfulPreview } from '~/diptyqueTheme/composable/useContentfulPreview';
import { useEngraving } from '~/diptyqueTheme/composable/useEngraving';
import { useHreflangs } from '~/diptyqueTheme/composable/useHreflangs';
import {
  lsGet,
  lsRemove,
  lsSet
} from '~/diptyqueTheme/composable/useLocalStorage';
import { useBundleStore } from '~/diptyqueTheme/stores/bundleProduct';
import { useCountriesStore } from '~/diptyqueTheme/stores/countries';
import { Logger } from '~/helpers/logger';
import { ProductTypeEnum } from '~/modules/catalog/product/enums/ProductTypeEnum';
import type { NostoMock, Product } from '~/modules/catalog/product/types';
import type { ProductDetailsQuery } from '~/modules/GraphQL/types';

export default defineComponent({
  name: 'ProductPage',
  components: {
    LoadWhenVisible,
    ProductSkeleton: () =>
      import('~/modules/catalog/product/components/ProductSkeleton.vue'),
    Page404: () => import('~/diptyqueTheme/pages/Page404.vue'),
    SimpleProduct: () =>
      import(
        '~/modules/catalog/product/components/product-types/simple/SimpleProduct.vue'
      ),
    BundleProduct: () =>
      import(
        '~/modules/catalog/product/components/product-types/bundle/BundleProduct.vue'
      ),
    ConfigurableProduct: () =>
      import(
        '~/modules/catalog/product/components/product-types/configurable/ConfigurableProduct.vue'
      ),
    GroupedProduct: () =>
      import(
        '~/modules/catalog/product/components/product-types/grouped/GroupedProduct.vue'
      ),
    InstagramFeed: () => import('~/components/InstagramFeed.vue'),
    MobileStoreBanner: () => import('~/components/MobileStoreBanner.vue'),
    RelatedProducts: () =>
      import('~/modules/catalog/product/components/RelatedProducts.vue'),
    UpsellProducts: () =>
      import('~/modules/catalog/product/components/UpsellProducts.vue'),
    SectionsList: () => import('templates/SectionsList.vue'),
    VaimoBreadcrumbs: () => import('atoms/VaimoBreadcrumbs.vue'),
    VaimoSubscriptionModal,
    VaimoTransparencyModal,
    SfLoader
  },
  scrollToTop: true,
  transition: 'fade',
  setup() {
    const { toggleSubscriptionModal } = useUiState();
    const { getFullProductBasedOnFullRequestPath, getBreadcrumbs } =
      useRequestPath();
    const bundleStore = useBundleStore();
    const { addTags } = useCache();
    const {
      app: { i18n, $vsf, $gtm }
    } = useContext();
    const { getViewItemDetails } = useGoogleTagManager();
    const route = useRoute();
    const { error: nuxtError } = useContext();
    const countriesStore = useCountriesStore();
    const { sectionList, getSectionList } = useContentfulPage('pdp');
    const { getCategoryBreadcrumbsStructuredData, getProductStructuredData } =
      useStructuredData();
    const { config } = useConfig();
    const isModalEnabled = config.value?.newsletter_subscribe_widget_enabled;
    const { updateNostoSession, recommendationTypes } = useNosto();
    const { getProductLink, isPreviewMode, productURL } =
      useContentfulPreview();

    const {
      params: { id }
    } = route.value;

    const product = ref<Product | null>(null);
    const productBlocks = ref([]);
    const productBelowBlocks = ref([]);
    const loading = ref<boolean | null>(true);
    const firstLoadDone = ref(false);
    const { canonicalProductLink } = useCanonical();
    const breadcrumbs = computed(() => getBreadcrumbs(product.value));

    // eslint-disable-next-line no-underscore-dangle
    const renderer = computed(() => {
      if (product.value?.__typename === ProductTypeEnum.SIMPLE_PRODUCT) {
        return ProductTypeEnum.SIMPLE_PRODUCT;
      }
      return ProductTypeEnum.BUNDLE_PRODUCT;
    });

    const fetchProductBaseData = async () => {
      try {
        const result = await getFullProductBasedOnFullRequestPath();

        if (result) {
          product.value = merge({}, product.value, (result as Product) ?? null);
        } else {
          doInitialLoading.value = false;
          return nuxtError({ statusCode: 404 });
        }
      } catch (error) {
        Logger.error(
          'Get Full Product Data from URL path by route [ERROR]',
          error
        );
        console.error(error);
        return nuxtError({ statusCode: 404 });
      }
    };

    const storeCode = i18n.localeProperties.code;
    const { engravingConfig, getProductEngravingConfig } = useEngraving();
    const getProductEngravingData = async () => {
      if (!product.value?.sku || !storeCode) return;

      await getProductEngravingConfig({
        sku: product.value.sku,
        store_code: storeCode
      });
    };

    const getBaseSearchQuery = () => ({
      filter: {
        sku: { eq: product.value?.sku },
        ...(product.value?.__typename !== 'BundleProduct' && {
          url_path: {
            eq:
              isPreviewMode.value && productURL.value
                ? productURL.value
                : route.value.path
          }
        })
      },
      configurations: Object.entries(route.value.query)
        .filter(([key]) => key !== 'wishlist')
        .map(([, value]) => value) as string[],
      is_preview: isPreviewMode.value
    });

    const fetchProductExtendedData = async (
      searchQuery = getBaseSearchQuery()
    ) => {
      const { data } =
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore "getProductPriceBySku" dynamically added by custom-middleware
        await $vsf.$magento.api.getProductPriceBySku<ProductDetailsQuery>(
          searchQuery
        );

      if (product.value && data?.products?.items?.length) {
        product.value = merge(
          {},
          product.value,
          data?.products?.items?.[0] as Product
        );
        bundleStore.numberOfSets = +product.value.number_of_sets ?? 0;
      }
    };

    const getPdpBlocksBelow = async () => {
      await getSectionList('pdp', product.value?.sku);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      productBlocks.value = sectionList.value?.productBlocks || [];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      productBelowBlocks.value = sectionList.value?.belowBlocks || [];

      if (
        !productBelowBlocks.value.some((element) => {
          let result = false;
          if (element) {
            if (typeof element['productGroupsCollection'] !== 'undefined') {
              for (const item of element['productGroupsCollection'].items) {
                if (item['type'] === 'nosto') return true;
              }
            }
          }
          return result;
        })
      ) {
        productBelowBlocks.value.push(getNostoMockData());
      }
    };

    //this returns a nosto block, for the case, when a content editor
    //did not explicitly add a block in contentful
    const getNostoMockData = (): NostoMock => {
      return {
        heading: i18n.t('You may also like nosto') as string,
        productGroupsCollection: {
          items: [
            {
              groupTitle: i18n.t('You may also like nosto') as string,
              nostoSlot: 'nosto-page-product1',
              sku: [],
              type: 'nosto'
            }
          ]
        },
        __typename: 'ProductSlider'
      };
    };

    const doInitialLoading = ref(true);

    const extendProductData = () => {
      const promises = [
        fetchProductExtendedData(),
        getPdpBlocksBelow(),
        getProductEngravingData()
      ];

      if (isPreviewMode.value) {
        promises.unshift(getProductLink());
      }

      return Promise.all(promises);
    };

    useFetch(async () => {
      loading.value = true;
      doInitialLoading.value = process.server;
      if (isPreviewMode.value) {
        await getProductLink();
      }
      await fetchProductBaseData();
      loading.value = false;
      firstLoadDone.value = true;
      if (process.client && !doInitialLoading.value) {
        await extendProductData(); //note: this must always be run after fetchProductBaseData
      }

      await getSectionList('pdp', product.value?.sku);

      if (product.value) {
        if (process.client) {
          updateNostoSessionForProduct(product.value);
        }
        $gtm.push(getViewItemDetails(product.value, 'pdp'));
      }

      const tags = [
        {
          prefix: CacheTagPrefix.View,
          value: `product-${id}`
        }
      ];

      const productTags = [
        {
          prefix: CacheTagPrefix.Product,
          value: product.value?.uid
        }
      ];

      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      addTags([...tags, ...productTags]);
    });

    const compoundBelowBlocks = computed(() => {
      const placeholderExist = productBelowBlocks.value?.some(
        (block) => block?.__typename === 'Placeholder'
      );

      const placeholder = {
        __typename: 'Placeholder',
        type: 'Magento: Upsell products [PDP only]'
      };

      return placeholderExist
        ? productBelowBlocks.value
        : [placeholder, ...productBelowBlocks.value];
    });

    const updateNostoSessionForProduct = async (product: Product) => {
      const ref = route.value.query?.nosto_ref;
      const options = ref ? { ref } : {};
      updateNostoSession(
        recommendationTypes.product,
        String(product.id),
        options
      );
    };

    onMounted(() => {
      nextTick(() => {
        window.scrollTo(0, 0);
        window.scroll({ top: -1, left: 0, behavior: 'smooth' });
        setTimeout(() => {
          window.scrollTo(-1, 0);
          // eslint-disable-next-line no-magic-numbers
        }, 300);
        if (product.value) {
          $gtm.push(getViewItemDetails(product.value, 'pdp'));
        }

        document.body.classList.add('has-sticky');
      });
    });

    onUnmounted(() => {
      document.body.classList.remove('has-sticky');
    });

    onBeforeMount(async () => {
      if (doInitialLoading.value) {
        doInitialLoading.value = false;
        await extendProductData();
        if (product.value) {
          updateNostoSessionForProduct(product.value);
        }
      }

      if (!countriesStore.countries.length) {
        countriesStore.loadCountriesList();
      }
      visitCount();
    });

    const visitCount = () => {
      if (!isModalEnabled) return;
      const currentDate = +new Date();
      const visitKey = 'pdp-visit-count';
      const expirationDateKey = 'pdp-visit-expiration-date';

      const setExpirationKey = () => {
        const expirationDate = new Date();
        expirationDate.setMonth(expirationDate.getMonth() + 1);
        lsSet(expirationDateKey, expirationDate.getTime().toString());
      };

      if (!lsGet(expirationDateKey)) {
        setExpirationKey();
      }

      const storedExpirationDate = parseInt(lsGet(expirationDateKey));

      if (currentDate >= storedExpirationDate) {
        setExpirationKey();
        lsRemove(visitKey);
      }

      let count = parseInt(lsGet(visitKey)) || 0;
      const maxVisits = 2;

      if (count >= maxVisits) {
        return;
      }

      count++;
      lsSet(visitKey, count.toString());
      if (count === maxVisits) {
        nextTick(() => {
          toggleSubscriptionModal();
        });
      }
    };

    const sectionListConfig = computed(() => {
      return {
        GoodToKnowPanel: {
          shortcodeSource: product.value
        },
        Placeholder: {
          pdpUpsellProducts: product.value?.upsell_products?.sort(
            (item1, item2) => +item1.position - +item2.position
          )
        }
      };
    });

    const metaDescription = computed(() => {
      // limit description to 131 characters
      const maxQtyOfChar = 131;
      let description = product.value?.pdp_long_description?.toString();

      if (!description) {
        return '';
      }

      if (description.length > maxQtyOfChar) {
        description = description.substring(0, maxQtyOfChar);
      }

      return description;
    });

    const variantName = computed(() => {
      const label =
        product.value?.variant_name?.length &&
        product.value?.variant_name[0]?.label;
      return label && label !== 'false' ? label : '';
    });

    const productTypeClass = computed(() => {
      switch (product.value?.__typename) {
        case ProductTypeEnum.SIMPLE_PRODUCT:
          return 'product-type__simple';
        case ProductTypeEnum.BUNDLE_PRODUCT:
          return 'product-type__bundle';
        default:
          return '';
      }
    });

    const metaTags = computed(() => {
      const title = product.value?.meta_title
        ? product.value?.meta_title
        : product.value?.name + ' ' + variantName.value;

      const magentoDescription = product.value?.meta_description
        ? product.value?.meta_description.trim()
        : '';

      const description = magentoDescription.length
        ? magentoDescription
        : metaDescription.value;

      return product.value?.name
        ? {
            title: `${title} | Diptyque Paris`,
            meta: [
              {
                hid: 'og:title',
                property: 'og:title',
                content: `${title} | Diptyque Paris`
              },
              {
                hid: 'description',
                name: 'description',
                content: `${description}`
              },
              {
                hid: 'og:description',
                name: 'og:description',
                content: `${description}`
              }
            ],
            link: [canonicalProductLink(product.value)],
            script: [
              {
                type: 'application/ld+json',
                json: getCategoryBreadcrumbsStructuredData(breadcrumbs.value)
              },
              {
                type: 'application/ld+json',
                json: getProductStructuredData(product.value)
              }
            ]
          }
        : {};
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    useMeta(() => metaTags.value);

    return {
      renderer,
      productBlocks,
      compoundBelowBlocks,
      loading,
      isPreviewMode,
      breadcrumbs,
      product,
      sectionListConfig,
      firstLoadDone,
      fetchProduct: fetchProductExtendedData,
      getFullProductBasedOnFullRequestPath,
      engravingConfig,
      productTypeClass
    };
  },

  async asyncData(ctx) {
    const { getHrefLangs } = useHreflangs(ctx);
    const hrefLangsLinks = await getHrefLangs();
    return {
      hrefLangsLinks
    };
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  head() {
    return {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      link: this.hrefLangsLinks
    };
  }
});
