import type { VsfContext } from '~/composables/context';
import { Logger } from '~/helpers/logger';
import { useCustomerStore } from '~/modules/customer/stores/customer';
import { useProductStore } from '~/modules/catalog/product/stores/product';
import type { Cart, FreeShippingThresholdInfo } from '~/modules/GraphQL/types';
import addProductsToCustomerCartFromRemoteMutation from '~/diptyqueTheme/customQueries/magento/addProductsToCustomerCartFromRemoteMutation';
import addProductsToGuestCartFromRemoteMutation from '~/diptyqueTheme/customQueries/magento/addProductsToGuestCartFromRemoteMutation';
import type { AddExternalProductsToCartResponse } from '~/modules/checkout/types';
// eslint-disable-next-line no-duplicate-imports
import type { UseCartAddItemsParams } from '~/composables';
import type { ApolloQueryResult } from '@apollo/client/core';

export const addItemsCommand = {
  execute: async (context: VsfContext, productItems: UseCartAddItemsParams, isAuthenticated: boolean) => {
    Logger.debug('[Magento]: Add external items to cart', {
      productItems
    });

    const apiState = context.$magento.config.state;
    const customerStore = useCustomerStore();
    const cartId = apiState.getCartId();

    if (!productItems) {
      return;
    }

    const productStore = useProductStore();

    const saveFreeShippingInfo = (info: FreeShippingThresholdInfo | null) => {
      productStore.setFreeShippingInfo(info);
    };

    const getCartResponse = (response: AddExternalProductsToCartResponse) => {
      const cartResponse = isAuthenticated
        ? (response?.addToCustomerCartFromRemote?.cart as Cart)
        : (response?.addToGuestCartFromRemote?.cart as Cart);

      if (cartResponse?.freeshipping_threshold_info) {
        saveFreeShippingInfo(cartResponse.freeshipping_threshold_info);
      }

      return cartResponse;
    };

    const addToCartMutation = isAuthenticated ? addProductsToCustomerCartFromRemoteMutation : addProductsToGuestCartFromRemoteMutation;

    const { data, errors } = (await context.$magento.api.customQuery({
      query: addToCartMutation,
      queryVariables: {
        cartItems: productItems,
        cartMask: cartId
      }
    })) as ApolloQueryResult<AddExternalProductsToCartResponse>;

    if (errors?.length && !cartId && customerStore.isTokenExpired) {
      Logger.error('useCart/addItemsCommand response errors', errors[0], cartId, customerStore);
      throw new Error(String('Your session has expired. Log in to continue your shopping.'));
    } else if (errors?.length) {
      Logger.error('useCart/addItemsCommand response errors', errors[0]);
      throw new Error(String(errors[0].message));
    }

    const userErrors = isAuthenticated ? data?.addToCustomerCartFromRemote?.user_errors || [] : data?.addToGuestCartFromRemote?.user_errors || [];
    const cartItems = isAuthenticated ? data?.addToCustomerCartFromRemote?.cart.items || [] : data?.addToGuestCartFromRemote?.cart.items || [];

    const hasOutOfStockItem = cartItems.some((item) => item?.product?.stock_status === 'OUT_OF_STOCK');

    const hasUndefinedError = userErrors.some((error) => error.code === 'UNDEFINED');

    if (userErrors.length > 0 && !hasUndefinedError && !hasOutOfStockItem) {
      Logger.error('useCart/addItemsCommand', userErrors);
      throw new Error(String(userErrors[0].message));
    }

    return getCartResponse(data as AddExternalProductsToCartResponse);
  }
};
