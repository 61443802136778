import { computed, ssrRef, useContext, useRoute } from '@nuxtjs/composition-api';
import { sharedRef } from '@vue-storefront/core';

import { useContentfulPreview } from '~/diptyqueTheme/composable/useContentfulPreview';
import { Logger } from '~/helpers/logger';
import { findActiveCategory } from '~/modules/catalog/category/helpers/findActiveCategory';
import { findCategoryAncestors } from '~/modules/catalog/category/helpers/findCategoryAncestors';
import { useCategoryStore } from '~/modules/catalog/category/stores/category';
import { CategoryTreeSearchParams } from '~/modules/catalog/category/types';

/**
 * Logic for finding the current product category and its parent and grandparent categories (ancestors)
 */
export function useTraverseCategory() {
  const { error: nuxtError, app } = useContext();
  const route = useRoute();
  const categoryTree = ssrRef(null, 'useTraverseCategory-' + app.i18n.locale);
  const flatCategoryArray = ssrRef({}, 'useTraverseflatCategoryArray-' + app.i18n.locale);
  const activeCategory = sharedRef(null, 'useTraverseCategory-activeCategory-' + app.i18n.locale);
  const loading = sharedRef(null, 'useTraverseCategory-loading-' + app.i18n.locale);
  const categoryStore = useCategoryStore();
  const { isPreviewMode } = useContentfulPreview();

  const getActiveCategory = (path) => {
    // on localhost the default store is localhost:3000/default/ but in a multi-store Magento instance this can change
    let urlPathToFind = path.replace(app.localePath('/l'), '').replace(`/${app.i18n.locale}/`, '').replace('.html', '').trim();
    if (isPreviewMode.value) {
      urlPathToFind = categoryStore.categoryClearUrl;
    }
    const foundCategory = findActiveCategory(categoryTree.value, urlPathToFind);

    return categoryTree.value !== null ? foundCategory : null;
  };

  const initCurrentCategory = () => {
    activeCategory.value = getActiveCategory(route.value.path);
  };

  const flattenCategoryTree = (tree) => {
    const flatArray = [];

    const traverse = (category) => {
      flatArray.push(category);
      if (category.children && category.children.length > 0) {
        category.children.forEach((child) => {
          traverse(child);
        });
        category.children = [];
      }
    };

    traverse(tree);
    flatCategoryArray.value = flatArray;
  };

  const loadCategoryTree = async () => {
    loading.value = true;

    try {
      const searchParams: CategoryTreeSearchParams = {};

      if (isPreviewMode.value) {
        searchParams.is_preview = true;
      }
      await categoryStore.load(searchParams);

      categoryTree.value = categoryStore.categories;
      categoryTree.value && flattenCategoryTree(categoryTree.value);
      initCurrentCategory();
    } catch (err) {
      Logger.error('useTraverseCategory/loadCategoryTree', err);
    } finally {
      loading.value = false;
    }
  };

  const activeCategoryDescription = computed(() => activeCategory.value?.description || '');

  const categoryAncestors = computed(() => (activeCategory.value ? findCategoryAncestors(categoryTree.value, activeCategory.value) ?? [] : []));

  return {
    loading,
    activeCategory,
    activeCategoryDescription,
    categoryAncestors,
    categoryTree,
    flatCategoryArray,
    initCurrentCategory,
    loadCategoryTree,
    isCategoryTreeLoaded: computed(() => categoryTree.value !== null),
    categoryStore
  };
}
