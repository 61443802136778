import type { VsfContext } from '~/composables/context';
import { Logger } from '~/helpers/logger';
import { useProductStore } from '~/modules/catalog/product/stores/product';
import { useCustomerStore } from '~/modules/customer/stores/customer';
import type { AddProductsToCartOutput, Cart, CartItemInput, FreeShippingThresholdInfo } from '~/modules/GraphQL/types';
import type { CustomQuery } from '~/types/core';

/** Params object used to add items to a cart */
export declare type AddProductsToCartInput = {
  cartId: string;
  cartItems: CartItemInput[];
};

interface ProductResponse {
  data?: {
    addProductsToCart: AddProductsToCartOutput;
  };
}

export const addItemCommand = {
  execute: async (context: VsfContext, { product, quantity, currentCart, productConfiguration, customQuery }, engravingCartItems?: string) => {
    Logger.debug('[Magento]: Add item to cart', {
      product,
      quantity,
      currentCart,
      engravingCartItems
    });

    const apiState = context.$magento.config.state;
    const customerStore = useCustomerStore();
    const cartId = apiState.getCartId();

    if (!product) {
      return;
    }

    const productStore = useProductStore();

    const saveFreeShippingInfo = (info: FreeShippingThresholdInfo | null) => {
      productStore.setFreeShippingInfo(info);
    };

    const getCartResponse = (productResponse: ProductResponse) => {
      const cartResponse = productResponse?.data?.addProductsToCart?.cart as unknown as Cart;

      if (cartResponse?.freeshipping_threshold_info) {
        saveFreeShippingInfo(cartResponse.freeshipping_threshold_info);
      }

      return cartResponse;
    };

    // eslint-disable-next-line no-underscore-dangle
    switch (product.__typename) {
      case 'SimpleProduct':
      case 'LinkedProductInterface': {
        let cartInput;
        const customQuery = {
          addProductsToCart: 'addProductsToCart'
        };

        if (engravingCartItems) {
          const productOption = product.options ?? [];
          const engravingOption = productOption.find((option) => option.title.toLowerCase() === 'engraving');
          const uid = engravingOption?.uid ?? '';

          cartInput = {
            cartId,
            cartItems: [
              {
                quantity,
                sku: product.sku,
                entered_options: [
                  {
                    uid,
                    value: engravingCartItems
                  }
                ]
              }
            ]
          };
        } else {
          cartInput = {
            cartId,
            cartItems: [
              {
                quantity,
                sku: product.sku
              }
            ]
          };
        }

        const productResponse = await context.$magento.api.addProductsToCart(cartInput, customQuery as CustomQuery);

        if (productResponse.errors?.length && !cartId && customerStore.isTokenExpired) {
          throw new Error(String('Your session has expired. Log in to continue your shopping.'));
        } else if (productResponse.errors?.length) {
          throw new Error(String(productResponse.errors[0].message));
        }

        const userErrors = productResponse?.data?.addProductsToCart?.user_errors || [];
        const cartItems = productResponse?.data?.addProductsToCart?.cart.items || [];

        const hasOutOfStockItem = cartItems.some((item) => item?.product?.stock_status === 'OUT_OF_STOCK');

        const hasUndefinedError = userErrors.some((error) => error.code === 'UNDEFINED');

        if (userErrors.length > 0 && !hasUndefinedError && !hasOutOfStockItem) {
          throw new Error(String(userErrors[0].message));
        }

        return getCartResponse(productResponse as ProductResponse);
      }
      case 'ConfigurableProduct': {
        const selectedOptions = Object.values(productConfiguration as object);

        const configurableCartInput: AddProductsToCartInput = {
          cartId,
          cartItems: [
            {
              quantity,
              sku: product.sku,
              selected_options: selectedOptions
            }
          ]
        };

        const configurableProduct = await context.$magento.api.addProductsToCart(configurableCartInput, customQuery as CustomQuery);
        Logger.debug('[Result]:', { data: configurableProduct.data });

        if (configurableProduct?.data?.addProductsToCart?.user_errors?.length > 0) {
          throw new Error(String(configurableProduct.data.addProductsToCart.user_errors[0].message));
        }

        return getCartResponse(configurableProduct as ProductResponse);
      }
      case 'BundleProduct': {
        const createEnteredOptions = () =>
          // eslint-disable-next-line implicit-arrow-linebreak
          product.bundle_options.map((bundleOption) => ({
            ...bundleOption,
            value: bundleOption.value.toString()
          }));

        const bundleCartInput: AddProductsToCartInput = {
          cartId,
          cartItems: [
            {
              quantity,
              sku: product.sku,
              entered_options: createEnteredOptions()
            }
          ]
        };

        const bundleProduct = await context.$magento.api.addProductsToCart(bundleCartInput, customQuery as CustomQuery);

        Logger.debug('[Result]:', { data: bundleProduct });

        if (bundleProduct?.data?.addProductsToCart?.user_errors?.length > 0) {
          throw new Error(String(bundleProduct.data.addProductsToCart.user_errors[0].message));
        }

        return getCartResponse(bundleProduct as ProductResponse);
      }
      case 'DownloadableProduct': {
        const downloadableCartInput: AddProductsToCartInput = {
          cartId,
          cartItems: [
            {
              quantity,
              sku: product.sku
            }
          ]
        };

        const downloadableProduct = await context.$magento.api.addProductsToCart(downloadableCartInput, customQuery as CustomQuery);

        Logger.debug('[Result DownloadableProduct]:', { data: downloadableProduct });

        if (downloadableProduct?.data.addProductsToCart?.user_errors?.length > 0) {
          throw new Error(String(downloadableProduct.data.addProductsToCart.user_errors[0].message));
        }

        // eslint-disable-next-line consistent-return
        return downloadableProduct?.data?.addProductsToCart?.cart as unknown as Cart;
      }
      case 'VirtualProduct': {
        const virtualCartInput: AddProductsToCartInput = {
          cartId,
          cartItems: [
            {
              quantity,
              sku: product.sku
            }
          ]
        };
        const virtualProduct = await context.$magento.api.addProductsToCart(virtualCartInput, customQuery as CustomQuery);

        Logger.debug('[Result VirtualProduct]:', { data: virtualProduct });

        if (virtualProduct?.data?.addProductsToCart?.user_errors?.length > 0) {
          throw new Error(String(virtualProduct.data.addProductsToCart.user_errors[0].message));
        }

        return getCartResponse(virtualProduct as ProductResponse);
      }
      default:
        // eslint-disable-next-line no-underscore-dangle
        throw new Error(`Product Type ${product.__typename} not supported in add to cart yet`);
    }
  }
};
