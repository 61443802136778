import type { Route } from 'vue-router';

import productGetters from '~/modules/catalog/product/getters/productGetters';
import type { Product } from '~/modules/catalog/product/types';
import cartGetters from '~/modules/core/getters/cartGetters';
import orderGetters from '~/modules/core/getters/orderGetters';
import type { Cart, CartItemInterface, Customer, GiftMessage, OrderItemInterface } from '~/modules/GraphQL/types';

import type { EccomerceItem, ItemDetails, PageType } from './types';

export const itemBrand = 'Diptyque Paris';

const lists = {
  home: 'Home Page',
  cart: 'Cart Page',
  minicart: 'Cart Sidebar',
  search: 'Search Page',
  plp: 'Category Page',
  pdp: 'Product Page',
  success: 'Success Page',
  slider: 'Product Slider',
  other: 'Other'
};

export const formatEventPrice = (price: number): string => {
  // Note that it will round to 2 decimal places, so the input 1.346 will return 1.35.
  // eslint-disable-next-line no-magic-numbers
  return (Math.round(price * 100) / 100).toFixed(2) || '0.00';
};

export const getListNameById = (id: string) => {
  return lists[id] || '';
};

export const getEventValue = (regularPrice: number, specialPrice: number) => {
  return specialPrice && specialPrice < regularPrice ? specialPrice : regularPrice;
};

export const getDiscountAmount = (regularPrice: number, specialPrice: number) => {
  const amount = specialPrice && specialPrice < regularPrice ? regularPrice - specialPrice : 0;

  // eslint-disable-next-line no-magic-numbers
  return Math.round(amount * 100) / 100;
};

export const getGiftWrapping = (cart: Cart): unknown => {
  if (!cart || !cart['gift_wrapping']) {
    return null;
  }

  return cart['gift_wrapping'];
};

export const getGiftMessage = (cart: Cart): GiftMessage => {
  if (!cart || !cart.gift_message) {
    return null;
  }

  return cart.gift_message;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getPaymentMethod = (): string => {
  // TODO: Investigate why this is not working
  // return cart?.selected_payment_method?.code ?? '';
  return 'adyen_cc';
};

export const getExtraServices = (cart: Cart): string => {
  const giftWrapping = getGiftWrapping(cart);
  const giftMessage = getGiftMessage(cart);
  const services = [];

  if (giftWrapping) {
    services.push('Gift Wrapping');
  }

  if (giftMessage) {
    services.push('Gift Message');
  }

  return services.join(' | ');
};

export const getOrderExtraServices = (giftWrapping, giftMessage): string => {
  const services = [];

  if (giftWrapping) {
    services.push('Gift Wrapping');
  }

  if (giftMessage) {
    services.push('Gift Message');
  }

  return services.join(' | ');
};

export const getCategoryNames = (product: Product): string[] => {
  const categoryNames = [];

  if (!product?.categories) {
    return categoryNames;
  }

  return product.categories.map((category) => category.name);
};

export const getItemCategoryNames = (product: CartItemInterface): string[] => getCategoryNames(product.product as Product);

export const getEditorialData = (
  product: Product & {
    editorial?: Array<{ label: string; value: string }>;
  }
): string => {
  if (!product || !product.editorial) {
    return '';
  }

  return (
    product.editorial
      ?.filter((item) => item.label !== 'false')
      ?.map((item) => item.label)
      ?.join(', ') ?? ''
  );
};

export const getItemEditorialData = (product: CartItemInterface): string => getEditorialData(product.product as Product);

export const getNewsletterSubscriptionStatus = (user: Customer): boolean => {
  return user ? user.is_subscribed : false;
};

const getCategoryPageExceptions = (to: Route): PageType => {
  const routePath = to?.path ?? '';
  let pageType: PageType = 'Product list page';

  if (routePath.includes('/l/idees-cadeaux') || routePath.includes('/l/gifts')) {
    pageType = 'Gift page';
  }

  return pageType;
};

const getContentPageExceptions = (to: Route): PageType => {
  const routePath = to?.path ?? '';
  let pageType: PageType = 'Content page';

  if (routePath.includes('gift-finder')) {
    pageType = 'Gift finder';
  }

  return pageType;
};

export const getPageType = (to: Route): PageType => {
  const routeName = to?.name?.split('___')[0];
  let pageType: PageType = '404';

  switch (routeName) {
    // General
    case 'home':
      pageType = 'Homepage';
      break;
    case 'category':
      pageType = getCategoryPageExceptions(to);
      break;
    case 'product':
      pageType = 'Product page';
      break;
    case 'page':
    case 'c-page':
      pageType = getContentPageExceptions(to);
      break;

    // Checkout
    case 'cart':
      pageType = 'Checkout shopping bag';
      break;
    case 'user-account':
      pageType = 'Checkout user account';
      break;
    case 'shipping-address':
      pageType = 'Checkout shippping';
      break;
    case 'shipping':
      pageType = 'Checkout shippping method';
      break;
    case 'payment':
      pageType = 'Checkout payment';
      break;
    case 'thank-you':
      pageType = 'Checkout confirmation';
      break;
    case 'error-page':
      pageType = 'Checkout error';
      break;

    // Search
    case 'search':
    case 'vaimoKlevuSearch-route':
      pageType = 'Other';
      break;

    // Account
    case 'customer-my-profile':
    case 'customer-addresses-details':
    case 'customer-addresses-details-new':
    case 'customer-addresses-details-edit':
    case 'customer-my-newsletter':
    case 'customer-my-wishlist':
    case 'customer-order-history':
    case 'customer-single-order':
    case 'customer-my-reviews':
    case 'customer-vault-card-list':
    case 'reset-password':
      pageType = 'Account page';
      break;

    // 404
    default:
      pageType = '404';
      break;
  }

  return pageType;
};

export const getItems = ({ products, quantity, listId }: ItemDetails): EccomerceItem[] => {
  const items: EccomerceItem[] = [];
  const goods: Product[] = Array.isArray(products) ? products : [products];

  goods.forEach((item, index) => {
    const categories = getCategoryNames(item);
    const regularPrice = productGetters.getPrice(item).regular || 0;

    items.push({
      item_name: productGetters.getName(item),
      item_id: productGetters.getProductSku(item),
      item_variant: getEditorialData(item),
      item_list_name: getListNameById(listId),
      index: index + 1,
      price: formatEventPrice(regularPrice),
      ...(quantity && { quantity }),
      item_brand: itemBrand,
      item_category: categories[0] || '',
      item_category2: categories[1] || '',
      item_category3: categories[2] || ''
    });
  });

  return items;
};

export const getSelectedItem = (products: Product[] | Product, index: number, listId = ''): EccomerceItem[] => {
  const items: EccomerceItem[] = [];
  const goods: Product[] = Array.isArray(products) ? products : [products];

  goods.forEach((item) => {
    const categories = getCategoryNames(item);
    const regularPrice = productGetters.getPrice(item).regular || 0;

    items.push({
      item_name: productGetters.getName(item),
      item_id: productGetters.getProductSku(item),
      item_variant: getEditorialData(item),
      item_list_name: getListNameById(listId),
      index,
      price: formatEventPrice(regularPrice),
      item_brand: itemBrand,
      item_category: categories[0] || '',
      item_category2: categories[1] || '',
      item_category3: categories[2] || ''
    });
  });

  return items;
};

export const getCartItems = (products: CartItemInterface[] | CartItemInterface, listId = ''): EccomerceItem[] => {
  const items: EccomerceItem[] = [];
  const goods: CartItemInterface[] = Array.isArray(products) ? products : [products];

  goods.forEach((item, index) => {
    const categories = getItemCategoryNames(item);
    const config = JSON.parse(localStorage.getItem('magento_config'));
    const cartProductPriceDisplayConfig = config?.tax_cart_display_price;
    const regularPrice = (cartProductPriceDisplayConfig === '2' ? item.prices?.row_total_including_tax?.value : item.prices?.row_total?.value) || 0;

    items.push({
      item_name: cartGetters.getItemName(item),
      item_id: cartGetters.getItemSku(item),
      item_variant: getItemEditorialData(item),
      item_list_name: getListNameById(listId),
      index: index + 1,
      price: formatEventPrice(regularPrice),
      quantity: cartGetters.getItemQty(item) || 1,
      item_brand: itemBrand,
      item_category: categories[0] || '',
      item_category2: categories[1] || '',
      item_category3: categories[2] || ''
    });
  });

  return items;
};

export const getOrderItems = (products: OrderItemInterface[] | OrderItemInterface, listId = ''): EccomerceItem[] => {
  const items: EccomerceItem[] = [];
  const goods: OrderItemInterface[] = Array.isArray(products) ? products : [products];

  goods.forEach((item, index) => {
    const categories = []; // TODO: Categories are not available in order item
    const price = orderGetters.getItemPriceInclTax(item) || 0;
    items.push({
      item_name: orderGetters.getItemName(item),
      item_id: orderGetters.getItemSku(item),
      item_list_name: getListNameById(listId),
      index: index + 1,
      price: formatEventPrice(price),
      quantity: orderGetters.getItemQuantityOrdered(item),
      item_brand: itemBrand,
      item_category: categories[0] || '',
      item_category2: categories[1] || '',
      item_category3: categories[2] || '',
      item_variant: getEditorialData(item as unknown as Product)
    });
  });

  return items;
};

export const getCartSamples = (products: CartItemInterface[] | CartItemInterface): string[] => {
  const samples = [];
  const goods: CartItemInterface[] = Array.isArray(products) ? products : [products];

  goods.forEach((item) => {
    const price = cartGetters.getItemPrice(item).regular || 0;

    if (price === 0) {
      samples.push(cartGetters.getItemName(item));
    }
  });

  return samples;
};

export const getOrderSamples = (products: OrderItemInterface[] | OrderItemInterface): string[] => {
  const samples = [];
  const goods: OrderItemInterface[] = Array.isArray(products) ? products : [products];

  goods.forEach((item) => {
    const price = orderGetters.getItemPrice(item);

    if (price === 0) {
      samples.push(orderGetters.getItemName(item));
    }
  });

  return samples;
};
